<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="确认付款">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="invoice-pay-confirm">
      <div class="item-wrap">
        <div class="item-line">
          <span>待支付手续费:</span>
          <span class="pay-amt">{{`¥ ${detail.handlingFee || 0}`}}</span>
        </div>
        <div class="item-line">
          <span>开票金额:</span>
          <span class="invoice-amt">{{`¥ ${detail.amt || 0}`}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>购票方:</span>
          <span>{{detail.providerName}}</span>
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <span>{{detail.sellerOrganCode}}</span>
        </div>
        <div class="item-line">
          <span>地址:</span>
          <span>{{detail.sellerAddress}}</span>
        </div>
        <div class="item-line">
          <span>电话:</span>
          <span>{{detail.sellerPhone}}</span>
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <span>{{detail.sellerBankName}}</span>
        </div>
        <div class="item-line">
          <span>账号:</span>
          <span>{{detail.sellerBankCard}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>销售方:</span>
          <span>{{detail.busiRegdName}}</span>
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <span>{{detail.busiRegdCode}}</span>
        </div>
        <div class="item-line">
          <span>地址:</span>
          <span>{{detail.busiAddress}}</span>
        </div>
        <div class="item-line">
          <span>电话:</span>
          <span>{{detail.busiMobile}}</span>
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <span>{{detail.busiBankName}}</span>
        </div>
        <div class="item-line">
          <span>账号:</span>
          <span>{{detail.busiBankCard}}</span>
        </div>
      </div>

      <div class="confirm-btn" @click="handleConfrim">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "invoice-pay-confirm",
  props: ["info"],
  components: {
  },
  data() {
    return {
      detail: ''
    };
  },
  mounted() {
    if (!this.info) {
      this.$router.back(-1);
    } else {
      this.detail = JSON.parse(decodeURIComponent(this.info));
    }
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleConfrim() {
      this.$router.replace(`/individual-business/invoice-pay-explain/${encodeURIComponent(this.info)}`)
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.invoice-pay-confirm {
  padding: 2.9333vw 5.3333vw 14.5333vw;
}
.item-wrap {
  width: 100%;
  padding: 8vw 5.3333vw;
  background-color: #ffffff;
  border-radius: 3.2vw;
  box-shadow: 0.4vw 0.4vw 1vw 0.43vw rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 3.2vw;
}
.item-line {
  font-size: 4vw;
  color: rgba(51,51,51,1);
  & + .item-line  {
    margin-top: 6vw;
  }
  display: flex;
  > span {
    display: inline-block;
    &:nth-of-type(1) {
      white-space: nowrap;
      // width: 33%;
      text-align: left;
      margin-right: 3%;
      color: rgba(51,51,51,0.7);
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      width: 64%;
      font-weight: 500;
    }
  }
  .pay-amt {
    font-size: 6.6667vw !important;
    font-weight: 500 !important;
    color: rgba(193,39,45,1) !important;
  }
  .invoice-amt {
    font-weight: 500 !important;
    color: rgba(0,0,0,1) !important;
  }
}
.confirm-btn {
  font-size: 4.5333vw;
  font-weight: bold;
  color: rgba(255,255,255,1);
  height: 9.3333vw;
  line-height: 9.3333vw;
  width: 100%;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  text-align: center;
  border-radius: 4.5333vw;
  margin-top: 5.3333vw;
}
</style>